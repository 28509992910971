<template>
  <div class="collapsible">
    <div class="collapsible-header" @click="toggle()">
      <h4>{{ title }}</h4>
      <i :class="['bx bx-chevron-down bx-sm text-black-lightest', iconRotation]"></i>
    </div>
    <article :class="['collapsible-content', aperture]">
      <slot></slot>
    </article>
    <Divider class="mt-4" />
  </div>
</template>
<script>
import Divider from "@/components/dividers/Divider.vue";
export default {
  name: "Collapsible",
  components: { Divider },
  props: {
    title: {
      type: String,
      default: "FAQ Title",
    },
    text: {
      type: String,
      default:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  },
  data() {
    return { aperture: "content-closed", iconRotation: "" };
  },
  methods: {
    toggle() {
      if (this.aperture === "content-closed") {
        this.aperture = "content-open";
        this.iconRotation = "bx-rotate-180";
      } else {
        this.aperture = "content-closed";
        this.iconRotation = "";
      }
    },
  },
};
</script>
<style scoped>
.collapsible-header {
  @apply flex items-center justify-between;
  @apply pb-2 pt-4;
  @apply cursor-pointer;
}

.collapsible-header h4 {
  @apply font-semibold text-base text-black-base;
}

.collapsible-content article {
  @apply pt-4 pb-6 w-full;
  @apply text-black-light font-medium;
  @apply leading-5;
}

.collapsible-content {
  @apply overflow-hidden;
  @apply transition-all ease-in-out duration-300;
}

.content-open {
  @apply h-auto;
  @apply transition-all ease-in-out duration-300;
}

.content-closed {
  @apply h-0;
  @apply transition-all ease-in-out duration-300;
}
</style>