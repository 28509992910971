<template>
  <div class="page">
    <Navbar id="navbar" mode="light" />
    <main class="page-main">
      <div class="page-section">
        <h1 class="page-title">Frequently asked questions</h1>
        <Tab :tabItems="tabs" class="mb-6" />
        <Collapsible
          v-for="(faq, index) in travelafrique"
          :key="index"
          :title="faq.title"
        >
          <div v-html="faq.text"></div>
        </Collapsible>
      </div>
    </main>

    <Footer class="footer" />
    <BottomBar class="layout-bar" />
  </div>
</template>
<script>
import Footer from "@/components/layout/Footer";
import Navbar from "@/components/layout/Navbar";
import Collapsible from "@/components/Collapsible.vue";
import BottomBar from "@/components/layout/BottomBar";
import Tab from "../../components/tabs/Tab.vue";
export default {
  name: "Faq",
  components: {
    Footer,
    Navbar,
    Collapsible,
    BottomBar,
    Tab,
  },
  data() {
    return {
      tabs: [
        { name: "Travel Afrique", isActive: true },
        { name: "Trip", isActive: false },
        { name: "COVID-19", isActive: false },
      ],
      travelafrique: [
        {
          title: "What does Travel Afrique do?",
          text: "Travel Afrique connects travelers from all over the world with local African travel agencies. In doing so, middlemen are cut out of the process with the goal of making travel and experiences in Africa more affordable and enjoyable for you. <ul><li class=\"li\">An incomparable expertise of the destination</li><li class=\"li\">A shortened response time</li><li class=\"li\">A tailor-made trip that suits your desires and budget</li><li class=\"li\">A final price almost 3 times cheaper than a classic travel agency</li><li class=\"li\">A much more equitable distribution of revenues for local travel agencies</li></ul>",
        },
        {
          title: "How can I contact you?",
          text: "You can easily contact us by sending us an email via contact(at)traveafrique.com",
        },
        {
          title: "How can I work with Travel Afrique as a local agency?",
          text: 'We welcome any application from a new interested local agency. However, we have a strict admission procedure to ensure the quality of the services offered on the platform. If you think that your travel agency is of great professional standards, you can apply <a href="/business/personal">here</a>.',
        },
      ],
    };
  },
  methods: {},
  computed: {},
};
</script>
<style scoped>
.review-container {
  @apply grid grid-flow-row  grid-cols-1 sm:grid-cols-2 gap-4;
}
.sheet-hidden {
  @apply opacity-0 transform scale-75 z-0;
}
.li {
  list-style: disc !important;
  list-style-position: inside !important;
}
.sheet-reveal {
  @apply opacity-100 transform scale-100 z-50;
}

.page-main {
  @apply w-full px-4 md:px-12;
}

.page-section {
  @apply w-full max-w-screen-md;
  @apply relative;
  @apply pt-32;
  left:50%;
  transform: translateX(-50%);
}

.section-title {
  @apply font-semibold text-2xl text-black-base max-w-screen-xl w-full;
  @apply left-1/2 relative transform -translate-x-1/2 mb-6;
}

#navbar {
  @apply fixed top-0 z-20 left-0;
}

.footer {
  @apply mt-20 pb-20 sm:pb-0;
}
.page-title {
  @apply w-full mb-12;
  @apply font-bold font-sans leading-tight text-black-base;
  @apply text-xl md:text-2xl lg:text-3xl;
}
.layout-bar {
  @apply fixed bottom-0 w-full sm:hidden;
}
</style>